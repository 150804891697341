import React, {Fragment} from 'react';
import styled from 'styled-components';
import {HelpMenu} from './HelpMenu';
import {Breakpoints, NavActions} from '../../Contexts/AppContext';
import CustomNav from '../../Components/CustomNav';
import {
  ChevronLeft,
  Gift,
  Hardware,
  Sparkle,
  Customize,
  Style,
  Plus,
  Minus,
} from '../../Components/SvgIcon';
import {useDimension} from '../../Hooks/AppHooks';
import {MaintainKnowledge} from '../../Domain/Help';

const MemberServicePage = props => {
  const {pathname} = props.location;
  const {desktop} = useDimension();
  const [expand, setExpand] = React.useState(null);

  const Items = [
    // FIXME: @guychienll future feature comment first
    // {
    //   icon: <Customize size={desktop ? 36 : 28} />,
    //   title: '客製化服務',
    //   content: (
    //     <div className="content-container">
    //       <img
    //         alt=""
    //         src=""
    //         style={{
    //           maxWidth: 284,
    //           width: '100%',
    //           height: 160,
    //           background: 'lightgray',
    //         }}
    //       />
    //       <div className="text">
    //         設計師依照客戶個人身形，服裝樣式及季節搭配…等，選出最適當的質感與花色，襯托出客人的自信與個人專屬的風采。
    //       </div>
    //     </div>
    //   ),
    // },
    {
      icon: <Sparkle size={desktop ? 36 : 28} />,
      title: '保養服務/知識',
      content: (
        <div className="content-container" style={{flexDirection: 'column'}}>
          <div style={{marginBottom: 4}}>
            承蒙選購，十分感謝!
            為了使您的物品使用更長久，在穿戴前，務必閱讀本注意事項:
          </div>
          {MaintainKnowledge.map((knowledge, index) => (
            <div key={index} style={{marginBottom: 4}}>
              <h4>{knowledge.title}</h4>
              {knowledge.data.map((k, i) => (
                <div style={{lineHeight: 1.57}} key={i}>
                  {k}
                </div>
              ))}
            </div>
          ))}
        </div>
      ),
    },
    // FIXME: @guychienll future feature comment first
    // {
    //   icon: <Hardware size={desktop ? 36 : 28} />,
    //   title: '維修服務',
    //   content: (
    //     <div className="content-container">
    //       TUN 提供各品牌維修服務，請前往
    //       <span className="link-gold" onClick={() => 0}>
    //         會員中心 > 維修服務
    //       </span>
    //       查看詳細說明。
    //     </div>
    //   ),
    // },
    // FIXME: @guychienll future feature comment first
    // {
    //   icon: <Gift size={desktop ? 36 : 28} />,
    //   title: '禮物包裝',
    //   content: <div className="content-container"></div>,
    // },
    // FIXME: @guychienll future feature comment first
    // {
    //   icon: <Style size={desktop ? 36 : 28} />,
    //   title: '個人風格諮詢',
    //   content: (
    //     <div className="content-container">
    //       TUN 提供穿搭風格諮詢建議，立即
    //       <span className="link-gold" onClick={() => 0}>
    //         開啟對話
    //       </span>
    //       開始線上諮詢吧！
    //     </div>
    //   ),
    // },
  ];

  return (
    <Fragment>
      {!desktop && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          }
          middle={<div>會員服務</div>}
        />
      )}
      <Wrapper smallDevice={!desktop}>
        <HelpMenu pathname={pathname} />
        <div className="right">
          <div className="header">會員服務</div>
          <div className="container">
            {Items.map((item, index) => (
              <ItemWrapper key={index} smallDevice={!desktop}>
                <div
                  className="title-container"
                  onClick={() =>
                    expand === index ? setExpand(null) : setExpand(index)
                  }>
                  <div className="left">
                    <div className="icon">{item.icon}</div>
                    <div className="title">{item.title}</div>
                  </div>
                  <div className="right">
                    {expand === index ? (
                      <Minus size={24} />
                    ) : (
                      <Plus size={24} />
                    )}
                  </div>
                </div>
                {expand === index && item.content}
              </ItemWrapper>
            ))}
          </div>
        </div>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  margin-bottom: 100px;
  padding: 40px 50px;
  display: flex;
  min-height: calc(100vh - 280px);

  & > .right {
    flex: 1;

    & > .header {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      margin-bottom: 28px;
    }

    & > .container {
      max-width: 656px;
      width: 100%;
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 20px;
    & > .right {
      & > .header {
        display: none;
      }
    }
  }
`;

const ItemWrapper = styled.div`
  flex: 1;
  padding: ${props => (props.smallDevice ? '18px 0px' : '20px 0px')};
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);

  & > .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    & > .left {
      display: flex;
      align-items: center;
      flex: 1;

      & > .icon {
        margin-right: ${props => (props.smallDevice ? '8px' : '12px')};
      }

      & > .title {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
      }
    }

    & > .right {
      cursor: pointer;
    }
  }

  & > .content-container {
    margin-top: 18px;
    margin-left: ${props => (props.smallDevice ? '36px' : '48px')};
    display: flex;
    flex-direction: ${props => (props.smallDevice ? 'column' : 'row')};

    & > img {
      margin-right: ${props => (props.smallDevice ? '0px' : '21px')};
      margin-bottom: ${props => (props.smallDevice ? '30px' : '0px')};
    }

    & > div {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
    }

    .link-gold {
      cursor: pointer;
      padding: 0px 4px;
      text-decoration: underline;
      color: #cca75c;
    }
  }
`;

export default MemberServicePage;
