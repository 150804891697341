import styled from 'styled-components';
import React, {useContext, useEffect} from 'react';
import {ChevronLeft} from './SvgIcon';
import {Actions, Context, NavActions} from '../Contexts/AppContext';
import {Color} from './Widget';

const CustomNav = props => {
  const {
    left = (
      <ChevronLeft
        onClick={() => {
          NavActions.navigate('/home');
        }}
      />
    ),
    middle = null,
    right = null,
    style,
    initNavType = 'normal',
    navType = null,
  } = props;

  useEffect(() => {
    Actions.setNavType(initNavType);
  }, [initNavType]);

  const app = useContext(Context);
  let _navType = navType || app.navType;

  const configs = {
    solid: {
      backgroundColor: Color.mainWhite,
      color: Color.mainDark,
      borderBottomColor: Color.mainDark_10,
    },
    normal: {
      backgroundColor: 'transparent',
      color: Color.mainDark,
      borderBottomColor: 'transparent',
    },
    revert: {
      backgroundColor: 'transparent',
      color: Color.mainWhite,
      borderBottomColor: 'transparent',
    },
  };

  return (
    <Wrapper
      borderBottomColor={configs[_navType].borderBottomColor}
      bgColor={configs[_navType].backgroundColor}
      color={configs[_navType].color}
      style={style}
      data-nav-anchor-id="nav">
      <div className="left">{left}</div>
      <div className="middle">{middle}</div>
      <div className="right">{right}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: var(--scroll-bar-height);
  height: var(--navbar-height);
  z-index: 10;
  background-color: ${props => props.bgColor};
  width: 100%;
  color: ${props => props.color};
  & svg,
  path {
    fill: ${props => props.color};
    color: ${props => props.color};
  }
  & > .middle {
    font-weight: 500;
  }
  & > .left {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 30px;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > .right {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 30px;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default CustomNav;
