import * as L from '../../Utils/Lang';
import {HELP_LIST_CONFIG} from '../../Domain/Help';
import {Breakpoints, NavActions} from '../../Contexts/AppContext';
import React from 'react';
import styled from 'styled-components';
import {FlexCol, FontSize} from '../../Components/Widget';

export const HelpMenu = props => {
  const {pathname} = props;
  return (
    <Menu className="menu">
      <div className="title">{L.d(HELP_LIST_CONFIG.title)}</div>
      {HELP_LIST_CONFIG.items.map((i, idx) => (
        <div
          key={idx}
          onClick={() => {
            NavActions.navigate(i.route);
          }}
          className={`item ${pathname === i.route ? 'active' : ''}`}>
          {L.d(i.title)}
        </div>
      ))}
    </Menu>
  );
};

const Menu = styled(FlexCol)`
  min-width: 340px;
  & > .title {
    font-size: ${FontSize.highlight}px;
    margin-bottom: 40px;
    font-weight: 300;
  }
  & > .item {
    font-size: ${FontSize.paragraph}px;
    font-weight: 300;
    margin-bottom: 16px;
    cursor: pointer;
  }
  & > .active {
    font-weight: bold;
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    display: none;
  }
`;
